import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import qs from "qs";

import {
  CustomerCreate,
  CustomerCreateMutation,
  CustomerCreateMutationVariables,
  CustomerInput,
  CustomerUpdate,
  CustomerUpdateDefaultAddress,
  CustomerUpdateDefaultAddressMutation,
  CustomerUpdateDefaultAddressMutationVariables,
  CustomerUpdateMutation,
  CustomerUpdateMutationVariables,
  GetCustomer,
  GetCustomerPaymentMethods,
  GetCustomerPaymentMethodsQuery,
  GetCustomerPaymentMethodsQueryVariables,
  GetCustomerQuery,
  GetCustomerQueryVariables,
  GetCustomerWithEventsAndActiveSubscriptionIds,
  GetCustomerWithEventsAndActiveSubscriptionIdsQuery,
  GetCustomerWithEventsAndActiveSubscriptionIdsQueryVariables,
  GetCustomers,
  GetCustomersQuery,
  GetCustomersQueryVariables,
} from "./api";

import { apolloShopifyMutation, apolloShopifyQuery, throws } from ".";

export function queryShopifyCustomers(
  client: ApolloClient<object>,
  query: GetCustomersQueryVariables["query"],
  first = 10,
  cursor?: string | null
) {
  return apolloShopifyQuery<GetCustomersQuery, GetCustomersQueryVariables>(
    {
      query: GetCustomers,
      variables: {
        query,
        first,
        cursor,
      },
    },
    client
  );
}

export function queryShopifyCustomer(id: string, client: ApolloClient<NormalizedCacheObject>) {
  return apolloShopifyQuery<GetCustomerQuery, GetCustomerQueryVariables>(
    {
      query: GetCustomer,
      variables: {
        id,
      },
    },
    client
  );
}

export function queryShopifyCustomerWithEventsAndActiveSubscriptions(
  id: string,
  client: ApolloClient<NormalizedCacheObject>
) {
  return apolloShopifyQuery<
    GetCustomerWithEventsAndActiveSubscriptionIdsQuery,
    GetCustomerWithEventsAndActiveSubscriptionIdsQueryVariables
  >(
    {
      query: GetCustomerWithEventsAndActiveSubscriptionIds,
      variables: {
        id,
      },
    },
    client
  );
}

export function queryShopifyCustomerPaymentMethods(
  client: ApolloClient<NormalizedCacheObject>,
  customerId: string,
  first = 1,
  cursor?: string
) {
  return apolloShopifyQuery<GetCustomerPaymentMethodsQuery, GetCustomerPaymentMethodsQueryVariables>(
    {
      query: GetCustomerPaymentMethods,
      variables: {
        customerId,
        first,
        cursor,
      },
    },
    client
  );
}

/**
 * Rob - Through experimentation this is how I believe updating customer addresses via customerUpdate mutation works:
 * * If the addresses array is empty shopify-side, it will add all the addresses passed in the `addresses` field array and assign them ids.
 * * If the addresses array is not empty shopify-side, the following will occur based on the input:
 *   * For all input addresses that provide an `id`, it will update the item with that id shopify-side
 *   * If any addresses exist shopify-side and are omitted in the input array, those items will be _removed_ shopify-side
 *   * If the input array provides a new address(es) (one without an id), one of two things can happen:
 *     * If the input array included _all_ of the shopify-side existing addresses with `id`s in addition to the new item(s), shopify will add the new item(s)
 *     * If the input array omitted _any_ of the shopify-side existing addresses along with providing new ones, shopify will _remove_ all existing items which
 *       were not specified in the input array as described earlier, however it will NOT commit the new items. In other words, the only way to add to a non-empty
 *       addresses array is to specify _all_ of the existing addresses with `id`s along with the items to be added. However, it is only necessary to provide the
 *       `id` fields for the existing items -- the remaining fields do not need to be provided if no update is required.
 */

export function mutationCustomerUpdate(input: CustomerInput, client: ApolloClient<NormalizedCacheObject>) {
  return throws(
    apolloShopifyMutation<CustomerUpdateMutation, CustomerUpdateMutationVariables>(
      {
        mutation: CustomerUpdate,
        variables: {
          input: {
            id: input.id!,
            addresses: input.addresses || [],
          },
        },
      },
      client
    )
  );
}

export function mutationCustomerUpdateEmail(input: CustomerInput, client: ApolloClient<NormalizedCacheObject>) {
  return throws(
    apolloShopifyMutation<CustomerUpdateMutation, CustomerUpdateMutationVariables>(
      {
        mutation: CustomerUpdate,
        variables: {
          input: {
            id: input.id!,
            email: input.email,
          },
        },
      },
      client
    )
  );
}

export function mutationCustomerUpdateDefaultAddress(
  customerId: string,
  addressId: string,
  client: ApolloClient<NormalizedCacheObject>
) {
  return throws(
    apolloShopifyMutation<CustomerUpdateDefaultAddressMutation, CustomerUpdateDefaultAddressMutationVariables>(
      {
        mutation: CustomerUpdateDefaultAddress,
        variables: {
          customerId,
          addressId: toCustomerAddressQueryIdParam(addressId),
        },
      },
      client
    )
  );
}

export function mutationCustomerCreate(input: CustomerInput, client: ApolloClient<NormalizedCacheObject>) {
  return throws(
    apolloShopifyMutation<CustomerCreateMutation, CustomerCreateMutationVariables>(
      {
        mutation: CustomerCreate,
        variables: {
          input,
        },
      },
      client
    )
  );
}

function toCustomerAddressQueryIdParam(gid: string) {
  return `${gid}${qs.stringify({ model_name: "CustomerAddress" }, { addQueryPrefix: true })}`;
}
